@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: poppins !important;
}

$primeryColor: #7a3cff;
$seconderyColor: #292858;
$tertiaryColor: #ffffff;
$fourthColor: black;
$fifthColor: #19f2be;
$sixthColor: #f8f8fe;

.primery-color {
  color: $primeryColor;
}

/* COMMON FONT STYLE */
.H1 {
  font: 500 80px/80px poppins;
}

.P1 {
  font: 300 22px/40px poppins;
}

.hide {
  display: none;
}

.show {
  display: inline-block;
}

.full-width-container {
  width: 100%;
  max-width: 100%;
}

.flex-container-right {
  display: flex;
  align-items: center;
}

.flex-between {
  justify-content: space-between;
}

.container-fluid-wrapper {
  margin: 0 auto;
  width: 76.38%;
  max-width: 1100px;
}

.vertical-padding {
  padding: 200px 0;
}

.content-area-padding {
  padding: 30px 0;
}

/* RESPONSIVE */

@media (max-width: 1024px) {
  .container-fluid-wrapper {
    margin: 0 auto;
    width: 80%;
  }
}

@media (max-width: 480px) {
  .container-fluid-wrapper {
    margin: 0 auto;
    width: 91.46%;
  }
}

// Box sizing reset for all elements
* {
  box-sizing: border-box;
}

// Reset margin and padding for all elements
body,
div,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}

// Reset font size and line height for headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  line-height: 1;
}

// Reset font size for paragraphs
p {
  font-size: 1em;
}

// Reset list styles for unordered and ordered lists
ul,
ol {
  list-style: none;
}

// Reset default styles for input elements
input {
  appearance: none;
  border: none;
  outline: none;
}

// Reset margin and padding for specific elements
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
input {
  margin: 0;
  padding: 0;
}

//  Reset for a
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
