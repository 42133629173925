@import "../../index.scss";

.scroll-header {
  position: fixed;
  z-index: 10;
  width: 100%;
}

.header-brand-logo {
  margin-top: -10px;
}

.header-list-items:hover {
  color: $seconderyColor !important;
}

.header-section {
  padding-top: 32px;
  height: 80px;
  background-color: $primeryColor;
  & > div {
    display: flex;
    justify-content: space-between;
  }
  .header-list-items {
    font: 500 16px/24px Poppins;
    color: $tertiaryColor;

    &:not(:last-child) {
      margin-right: 50px;
    }
  }
  .header-hamburger-icon {
    display: none;
  }
  .header-hamburger-menu-active {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header-section {
    padding-top: 28px;
    height: 70px;
    .header-brand-logo {
      width: 156px;
    }
    .header-list-items {
      font: 500 14px/20px Poppins;
      color: $tertiaryColor;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .header-section {
    .header-hamburger-icon {
      display: inline-block !important;
    }
    .header-hamburger-menu-active {
      display: flex;
      flex-direction: column;
      width: 100%;
      z-index: 2;
      position: relative;
      background-color: $primeryColor;
      font-family: poppins !important;
      font-weight: 500;
      .header-list-items-active {
        padding: 15px 10%;
        color: $tertiaryColor;
      }
      .header-list-items-active:hover {
        background-color: $seconderyColor;
        color: $tertiaryColor;
      }
    }
    .header-list-items {
      display: none;
    }
  }
}
