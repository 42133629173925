@import "../../index.scss";

.common-btn:hover {
  background-color: $seconderyColor !important;
  color: $tertiaryColor !important;
}

//Hero Section Styles
.hero-section {
  background-color: $primeryColor;
  color: $tertiaryColor;
  padding: 100px 0 120px 0;
  .hero-d-flex {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    .hero-content-area {
      width: 486px;
      .hero-header {
        font: 600 66px/73px Poppins;
      }
      .hero-content {
        font: 300 18px/33px Poppins;
        padding: 50px 0;
      }
      .common-btn {
        background-color: $fifthColor;
        color: $seconderyColor;
        padding: 20px 32px;
        border: none;
        border-radius: 5px;
        font: 700 18px/27px Poppins;
      }
    }
    .hero-img {
      border-radius: 8px;
      width: 525px;
      margin-left: 50px;
    }
  }
}

@media (max-width: 1300px) {
  .hero-section .hero-d-flex .hero-img {
    width: 450px;
    margin-top: 10px;
    margin-left: 30px;
  }
}

@media (max-width: 1024px) {
  .hero-section {
    padding: 70px 0 50px 0;
    .hero-d-flex {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      .hero-content-area {
        width: 525px;
        .hero-header {
          font: 600 66px/73px Poppins;
          padding-top: 44px;
        }
        .hero-content {
          font: 300 18px/33px Poppins;
          padding: 34px 0;
        }
        .common-btn {
          padding: 20px 32px;
          font: 700 18px/27px Poppins;
        }
      }
      .hero-img {
        width: 525px;
        margin-left: 0px;
      }
    }
  }
}

@media (max-width: 768px) {
  .hero-section {
    .hero-d-flex {
      .hero-content-area {
        width: 100%;
        .hero-header {
          font: 600 32px/40px Poppins;
          padding-top: 44px;
        }
        .hero-content {
          font: 300 14px/26px Poppins;
          padding: 34px 0;
        }
        .common-btn {
          padding: 18px 24px;
          font: 700 14px/21px Poppins;
        }
      }
      .hero-img {
        width: 96%;
        margin-left: 0px;
      }
    }
  }
}

//About Section Styles

.about-section {
  position: relative;
  background-color: $sixthColor;
  color: $seconderyColor;
  .about-d-flex {
    display: flex;
    justify-content: space-between;
    .about-content-area {
      padding: 201.5px 0 120px 0;
      .about-header {
        font: 600 66px/73px Poppins;
      }
      .about-content {
        font: 300 18px/33px Poppins;
        padding: 50px 0 0 0;
        width: 560px;
      }
      .about-client {
        padding: 81.5px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-self: center;
        text-align: center;
        span:nth-child(2) {
          padding: 0 57.5px;
          margin: 0 57.5px;
          border-left: 1px solid rgba(0, 0, 0, 0.08);
          border-right: 1px solid rgba(0, 0, 0, 0.08);
        }
        .about-client-item-img {
          width: 95px;
          height: 95px;
        }
        .about-client-name {
          font: 500 20px/24px Poppins;
          margin: 16px 0 12px 0;
          max-width: 95px;
        }
        .about-client-role {
          font: 300 14px/21px Poppins;
        }
      }
    }
    .about-img-main {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      img {
        height: 100%;
        width: 100%;
        max-width: 695px;
      }
    }
  }
  .about-img-main-hide img {
    display: none;
  }
}

@media (min-width: 2700px) {
  .about-section .about-d-flex .about-img-main {
    right: 20% !important;
  }
}

@media (min-width: 1700px) {
  .about-section .about-d-flex .about-img-main {
    right: 10%;
  }
}

@media (max-width: 1470px) {
  .about-section {
    .about-d-flex {
      .about-content-area {
        .about-content {
          width: 55%;
        }
        .about-client {
          span:nth-child(2) {
            padding: 0 3%;
            margin: 0 4%;
          }
        }
      }
      .about-img-main {
        img {
          max-width: 570px;
        }
      }
    }
  }
}

@media (max-width: 1270px) {
  .about-section {
    .about-d-flex {
      .about-content-area {
        .about-content {
          width: 490px;
        }
        .about-client {
          span:nth-child(2) {
            padding: 0 25px;
            margin: 0 24px;
          }
        }
      }
      .about-img-main {
        img {
          max-width: 500px;
        }
      }
    }
  }
}

@media (max-width: 1140px) {
  .about-section {
    .about-d-flex {
      .about-content-area {
        .about-content {
          width: 450px;
        }
        .about-client {
          span:nth-child(2) {
            padding: 0 17px;
            margin: 0 17px;
          }
        }
      }
      .about-img-main {
        img {
          max-width: 450px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .about-section {
    .about-d-flex {
      justify-content: center;
      .about-content-area {
        padding: 70px 0 50px 0;
        .about-content {
          width: 100%;
        }
        .about-client {
          padding: 46px 0 0 0;
          justify-content: center;
          span:nth-child(2) {
            padding: 0 57.5px;
            margin: 0 57.5px;
          }
        }
      }
      .about-img-main {
        display: none;
      }
    }
  }
  .about-img-main-hide img {
    display: inline-block !important;
    width: 100% !important;
    height: 575px;
    object-fit: cover;
    object-position: center center;
  }
}

@media (max-width: 768px) {
  .about-section {
    .about-d-flex {
      .about-content-area {
        .about-header {
          font: 600 32px/40px Poppins;
        }
        .about-content {
          font: 300 14px/26px Poppins;
          padding: 34px 0 0 0;
          width: 100%;
        }
        .about-client {
          padding: 30px 0 0 0;
          height: 200px;
          display: flex;
          align-items: flex-start;
          span:nth-child(2) {
            padding: 0 19px;
            margin: 0 19px;
          }
          .about-client-item-img {
            width: 70px;
            height: 70px;
          }
          .about-client-item {
            height: 76px;

            .about-client-name {
              font: 500 14px/16px Poppins;
              margin: 16px 0 12px 0;
            }
            .about-client-role {
              font: 300 12px/21px Poppins;
            }
          }
        }
      }
    }
  }
  .about-img-main-hide img {
    height: 375px;
  }
}

//Showcase Section Styles

.showcase-section {
  background-color: $tertiaryColor;
  color: $seconderyColor;
  .showcase-content-area {
    padding: 120px 0;
    .showcase-header {
      font: 600 66px/73px Poppins;
    }
    .showcase-content {
      font: 300 18px/33px Poppins;
      padding: 50px 0 39px 0;
      width: 560px;
    }
    .showcase-card-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .showcase-card-col {
        flex: 0 0 auto;
        width: 16.6%;
        margin-top: 50px;
        img {
          width: 142px;
          height: 142px;
        }
        p {
          font: 500 16px/22px Poppins;
          color: $seconderyColor;
          width: 142px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .showcase-section
    .showcase-content-area
    .showcase-card-row
    .showcase-card-col {
    width: 25%;
  }
}

@media (max-width: 820px) {
  .showcase-section
    .showcase-content-area
    .showcase-card-row
    .showcase-card-col {
    width: 33%;
  }
}

@media (max-width: 635px) {
  .showcase-section {
    .showcase-content-area {
      .showcase-card-row {
        .showcase-card-col {
          width: 33%;
          margin-top: 20px;
          img {
            width: 80px;
            height: 80px;
          }
          p {
            font: 500 12px/15px Poppins;
            width: 80px;
          }
        }
      }
    }
  }
}

//showcase content area
@media (max-width: 1024px) {
  .showcase-section {
    .showcase-content-area {
      padding: 100px 0;
    }
  }
}

@media (max-width: 768px) {
  .showcase-section {
    .showcase-content-area {
      padding: 70px 0 50px 0;
      .showcase-header {
        font: 600 32px/40px Poppins;
      }
      .showcase-content {
        font: 300 14px/26px Poppins;
        padding: 34px 0 0 0;
        width: 100%;
      }
    }
  }
}

//Team Section Style

.team-section {
  background-image: linear-gradient(#f6f2fd, #fff);
  color: $seconderyColor;
  .team-content-area {
    padding: 160px 0 120px 0;
    .team-header {
      font: 600 40px/40px Poppins;
      color: $primeryColor;
      padding-top: 25px;
    }
    .team-content {
      font: 400 26px/40px Poppins;
      width: 60%;
      color: $seconderyColor;
    }
    .team-card-row {
      display: flex;
      justify-content: flex-start;
      & > :nth-child(2) {
        margin: 0 47px;
        padding: 0 47px;
        border-left: 1px solid #f0f0f0;
        border-right: 1px solid #f0f0f0;
      }
      .team-card-col {
        flex: 0 0 auto;
        width: 30%;
        margin-top: 110px;
        img {
          width: 38px;
        }
        h6 {
          font: 500 26px/39px Poppins;
          margin: 26px 0 13px 0;
        }
        p {
          font: 400 16px/24px Poppins;
          color: $seconderyColor;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .team-section .team-content-area {
    padding: 100px 0;
    .team-card-row {
      & > :nth-child(2) {
        margin: 0 30px;
        padding: 0 30px;
      }
      .team-card-col {
        margin-top: 80px;
      }
    }
  }
}

@media (max-width: 768px) {
  .team-section {
    .team-content-area {
      padding: 70px 0 50px 0;
      .team-header {
        font: 600 30px/32px Poppins;
      }
      .team-content {
        font: 400 20px/32px Poppins;
        width: 100%;
      }
      .team-card-row {
        flex-direction: column;
        & > :nth-child(2) {
          margin: 0 0;
          padding: 0;
          border: none;
        }
        .team-card-col {
          flex: 0 0 auto;
          width: 100%;
          margin-top: 41px;
          img {
            width: 38px;
          }
          h6 {
            font: 500 22px/33px Poppins;
            margin: 9px 0;
          }
          p {
            font: 400 14px/21px Poppins;
            color: $seconderyColor;
            width: 100%;
          }
        }
      }
    }
  }
}

//GetInTouch area styles

.getInTouch-bg {
  margin: 0 auto;
  width: 76.38%;
  max-width: 1100px;
}

.getInTouch-section {
  display: flex;
  justify-content: space-between;
  padding: 60px 0 120px 0;
  .getInTouch-contact-area {
    background-color: $primeryColor;
    color: $tertiaryColor;
    padding: 60px;
    h3 {
      font: 600 80px/70px Poppins;
      padding-bottom: 110px;
    }
    .getInTouch-contact-item {
      display: flex;
      &:nth-child(3) {
        margin: 26px 0;
        padding: 26px 0;
        border-top: 1px solid #8b55ff;
        border-bottom: 1px solid #8b55ff;
      }
      img {
        margin-right: 14px;
      }
      span {
        font: 300 18px/26px Poppins;
      }
    }
  }
  .getInTouch-input-area {
    padding: 0 0 0 97px;
    .getInTouch-input-item {
      display: flex;
      flex-direction: column;
      .getInTouch-input-label {
        font: 500 15px/22.5px Poppins;
        margin-bottom: 10px;
      }
      .getInTouch-input-common {
        width: 478px;
        height: 62px;
        background-color: #eef1fa;
        border: none;
        border-radius: 3px;
        margin-bottom: 36px;
        padding: 2px 10px;
        &:hover {
          background-color: #fff;
          border: 1px solid $primeryColor;
        }
      }
    }
    .common-btn {
      background-color: $fifthColor;
      padding: 20px 32px;
      border: none;
      border-radius: 5px;
      font: 700 18px/27px Poppins;
    }
  }
}
.getInTouch-text-label {
  font: 500 15px/22.5px Poppins;
  margin-bottom: 10px;
  display: inline-block;
}
.getInTouch-text-area {
  height: 236px !important;
  width: 478px;
  height: 62px;
  background-color: #eef1fa;
  border: none;
  border-radius: 3px;
  margin-bottom: 36px;
  padding: 10px 10px;
  &:hover {
    background-color: #fff;
    border: 1px solid $primeryColor;
  }
}

@media (max-width: 1200px) {
  .getInTouch-section {
    .getInTouch-contact-area {
      padding: 30px;
      width: 50%;
      h3 {
        font: 600 50px/60px Poppins;
        width: 150px;
        padding-bottom: 40px;
      }
    }
    .getInTouch-input-area {
      padding: 0 0 0 0;
      .getInTouch-input-item {
        .getInTouch-input-common {
          width: 280px;
        }
      }
      .getInTouch-text-area {
        width: 280px;
      }
    }
  }
}

@media (max-width: 768px) {
  .getInTouch-bg {
    width: 100%;
  }

  .getInTouch-section {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    .getInTouch-contact-area {
      width: 100%;
      padding: 60px 10%;
      h3 {
        font: 600 36px/40px Poppins;
        width: 100%;
        padding-bottom: 47px;
      }
      .getInTouch-text-area {
        margin: 20px 0;
        padding: 20px 0;
      }
      .getInTouch-contact-item {
        img {
          margin-right: 14px;
        }
        span {
          font: 300 15px/22px Poppins;
        }
      }
    }
    .getInTouch-input-area {
      padding: 38px 0 50px 20px;
      .getInTouch-input-item {
        .getInTouch-input-label {
          font: 500 15px/22.5px Poppins;
          margin-bottom: 10px;
        }
        .getInTouch-input-common {
          width: 315px;
          margin-bottom: 20px;
        }
      }
      .common-btn {
        padding: 18px 24px;
        font: 700 14px/21px Poppins;
      }
      .getInTouch-text-label {
        font: 500 15px/22.5px Poppins;
        margin-bottom: 10px;
      }
      .getInTouch-text-area {
        width: 315px;
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 340px) {
  .getInTouch-section
    .getInTouch-input-area
    .getInTouch-input-item
    .getInTouch-input-common {
    width: 250px;
    margin-bottom: 16px;
  }
  .getInTouch-section .getInTouch-input-area .getInTouch-text-area {
    width: 250px;
    margin-bottom: 16px;
  }
}

/*Loading Section*/

.Loading-container {
  .spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7a3cff;
    z-index: 999999;
  }

  .sk-folding-cube {
    position: absolute;
    top: 48%;
    left: 48%;
    width: 40px;
    height: 40px;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }

  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .sk-folding-cube .sk-cube:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #19f2be;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
  }

  .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
  }

  .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
  }

  .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }

  @-webkit-keyframes sk-foldCubeAngle {
    0%,
    10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%,
    75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%,
    100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }

  @keyframes sk-foldCubeAngle {
    0%,
    10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%,
    75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%,
    100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
}
