@import "../../index.scss";

.bg-img-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  top: 0px;
  z-index: -1;
}

.promo-section {
  padding: 233.5px 0 150px 0;
  position: relative;
  .promo-main {
    text-align: center;
    .promo-header {
      font: 600 86px/90px Poppins;
      color: $seconderyColor;
    }
    .promo-content {
      margin-top: 50px;
      font: 400 28px/36px Poppins;
      color: $seconderyColor;
    }
  }
  .card-items {
    touch-action: manipulation;
  }
}

.scroll-icon {
  position: fixed;
  z-index: 10;
  margin: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  right: 0;
  bottom: 0;
  width: 50px;
}

@media (max-width: 768px) {
  .promo-section {
    padding: 150px 0 50px 0;
    .promo-main {
      .promo-header {
        font: 600 40px/46px Poppins;
      }
      .promo-content {
        margin-top: 28px;
        font: 400 18px/24px Poppins;
      }
    }
  }
}
