@import "../../index.scss";

.footer-list-item:hover {
  color: $primeryColor !important;
}

.footer-section {
  padding-top: 60px;
  background-color: #3c1b84;
  .footer-top-content {
    display: flex;
    justify-content: space-between;
    .footer-list-item {
      font: 500 16px/24px Poppins;
      color: $tertiaryColor;
      &:not(:last-child) {
        margin-right: 50px;
      }
    }
  }
  .footer-bottom-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding-top: 66px;
    .footer-social-item img {
      height: 22px;
      width: 22px;
      margin: 0 40px 40px 0;
    }
    .footer-pp {
      font: 300 13px/16.25px Lexend Deca;
      color: #b4a1dc;
      padding-bottom: 60px;
    }
  }
}

@media (max-width: 1024px) {
  .footer-section {
    padding-top: 50px;
    .footer-top-content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .footer-logo img {
        width: 156px;
      }
      .footer-list-item {
        font: 500 12px/20px Poppins;
        color: $tertiaryColor;
        padding: 40px 0 0 0;
        &:not(:last-child) {
          margin-right: 3%;
        }
      }
    }
    .footer-bottom-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: self-start;
      align-content: center;
      padding-top: 40px;
      .footer-social-item img {
        height: 22px;
        width: 22px;
        margin: 0 40px 40px 0;
      }
      .footer-pp {
        font: 300 13px/16.25px Lexend Deca;
        color: #b4a1dc;
        padding-bottom: 50px;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer-section {
    .footer-top-content {
      .footer-list-item {
        font: 500 11px/16.5px Poppins;
      }
    }
    .footer-bottom-content {
      .footer-pp {
        font: 300 12px/21px Lexend Deca;
      }
    }
  }
}
