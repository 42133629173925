@import "../../index.scss";
.card-items {
  margin: 154.5px 0 0px 12%;
  display: flex;
  overflow: auto;
  .card-img-container {
    position: relative;
    display: inline-block;
    margin-right: 25px;
    .card-img {
      width: 525px;
      height: 610px;
      border-radius: 8px;
    }
    .card-img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: self-start;
      padding: 30px 36px;
      margin-right: 10px;
      color: #fff;
      background: linear-gradient(360deg, #7a3bff 0%, rgba(0, 0, 0, 0) 50%);
      border-radius: 8px;
      height: 100%;
      width: 100%;
      .card-header {
        font: 700 40px/60px Poppins;
      }
      .card-content {
        margin: 10px 0 30px 0;
        font: 300 16px/27px Poppins;
      }
      .card-share {
        width: 168.75px;
        height: 50px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .card-items {
    margin: 50px 0 0px 90px;
    .card-img-container {
      .card-img-overlay {
        padding: 32px;
      }
    }
  }
}

@media (max-width: 768px) {
  .card-items {
    margin: 50px 0 0px 26px;
    .card-img-container {
      .card-img {
        width: 270px;
        height: 380px;
      }
      .card-img-overlay {
        padding: 26px;
        margin-right: 10px;
        .card-header {
          font: 700 28px/39px Poppins;
        }
        .card-content {
          margin: 10px 0 30px 0;
          font: 300 14px/21px Poppins;
        }
        .card-share {
          width: 105.66px;
          height: 31.42px;
        }
      }
    }
  }
}
